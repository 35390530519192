import { DefaultSeoProps } from "next-seo";

export const SEO_CONFIG: DefaultSeoProps = {
  defaultTitle: "COLD CASE – Une passionnante enquête policière à domicile",
  openGraph: {
    type: "website",
    locale: "fr_FR",
    url: "https://www.cold-case.fr/",
    site_name: "Le jeu d'enquête à la maison – COLD CASE – Jouez au détective !",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
  robotsProps: {
    notranslate: true,
  },
};
