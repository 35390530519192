import { ConsentProvider as _ConsentProvider } from "@akte-mord/common";
import { GROUPS, PROCESSES } from "./data";
import React from "react";

export const ConsentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <_ConsentProvider
    groups={GROUPS}
    processes={PROCESSES}
    storageKey={`akte-mord-${process.env.NEXT_PUBLIC_LNG ?? "fr"}-consent-management`}
  >
    {children}
  </_ConsentProvider>
);
